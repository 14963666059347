import * as React from "react";
import { Button } from "primereact/button";
import { FGrid } from "@fresche/ui-lib-react";
import { Column } from "primereact/column";
import { DataTable, DataTableSortOrderType } from "primereact/datatable";
import { RefObject } from "react";
import { Menu } from "primereact/menu";

import { InputText } from "primereact/inputtext";

import {FGridProps} from "@fresche/ui-lib-react/dist/lib/grid/f-grid/f-grid";
import CameraIcon from '@material-ui/icons/CameraAlt';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import NoteIcon from '@material-ui/icons/Note';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { TerminalCommunicationService, model } from "@fresche/terminal-lib";


export default class OptumGrid extends FGrid {
    public gearMenu: RefObject<Menu> = React.createRef<Menu>();
    
    public dt2: RefObject<DataTable> = React.createRef<DataTable>();

    public constructor(props:FGridProps) {
        super(props);
        this.applySort = this.applySort.bind(this);
    }

    getGridDataTable(): React.ReactElement {
        // const parentTable = super.getGridDataTable();
        // NOT POSSIBLE TO MODIFY parentTable
        // return parentTable;
        
        return <DataTable
            autoLayout={true}
            resizableColumns={false}
            columnResizeMode={'fit'}
            // @ts-ignore
            totalRecords={this.totalRecords}
            paginatorTemplate="PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport "
            currentPageReportTemplate="Rows per page"
            rowsPerPageOptions={[10, 25, 50]}
            pageLinkSize={1}
            rowHover={true}
            dataKey="index"
            paginator={true}
            // @ts-ignore
            rows={this.numRows}
            lazy={true}
            // @ts-ignore
            first={this.firstRecordIndex}
            value={this.model['subfile']}
            header={
                <Menu
                    model={
                        this.getMenuItems()
                    }
                    popup
                    id={"popup_menu"}
                    ref={this.gearMenu}
                    // @ts-ignore
                    baseZIndex={3000}
                    appendTo={document.body}
                    // @ts-ignore
                    style={{zIndex:9999}}
                />
            }
            // header={this.getGridDataTableHeader()}
            // @ts-ignore
            selection={this.selectedRows}
            onPage={this.getPage}
            onSelectionChange={(e: any): void => this.onSelectionChange(e)}
            rowClassName={this.rowClass}
            ref={this.dt2}
            onSort={this.applySort}
            responsiveLayout={"scroll"}
            sortField={this.getSortField()} sortOrder={this.getSortOrder()}
        >
            {(this.selectionMode !== 'none' && this.selectionMode !== 'noradio') && (
                <Column className="no-default-select" selectionMode={this.convertSelectionModeForPrime(this.selectionMode)} style={{ width: '45px', maxWidth: '45px' }} />
            )}
            {/* Add gear column */}
            {this.selectionMode !== 'none' && (
                <Column style={{ width: '35px' }} key={0} body={(rowData: any, props: any) =>
                    <Button
                        label={"settings"}
                        type="button"
                        className="p-button-secondary p-button-gear material-icons"
                        onClick={(event) => {
                            this.gearMenu.current.setState({
                                rowData: rowData,
                                rowIndex: props.rowIndex
                            });
                            this.gearMenu.current.toggle(event)
                        }}
                        aria-controls="popup_menu"
                        aria-haspopup
                    />
                } />
            )}
            {
                // @ts-ignore
                this.gridDefinition.columns.map((col: any, index: any): any => {
                    if (!this.hiddenColumn(col[0].field)) {
                        if(col[0]?.actionOnFieldSelect) {
                            return <Column key={index}
                        style={this.getColumnWidth(col[0])}
                        field={col[0].field}
                        header={this.getColumnHeader(col[0])}
                        columnKey={(index).toString()}                            
                        body={(rowData) => 
                            <div className="selection-box">
                                <div className="content-input highlight underline blu-color no-label f-text">
                                    <div className="size-max resizable-container">
                                        <a onClick = {()=> {this.replyToBackend(this.props.model, rowData, col[0].field)}}                        
                                             className="p-inputtext p-component p-filled fp-inputtext">
                                                {rowData.fields[this.getRowDataFieldIndex(col[0].field)]}</a>
                                    </div>
                                </div>
                            </div>
                        }
                        sortable={this.isSortable(col[0])}/>;
                        } else {
                            return <Column key={index}
                            style={this.getColumnWidth(col[0])}
                            field={col[0].field}
                            header={this.getColumnHeader(col[0])}
                            columnKey={(index).toString()}
                            body={col[0].field == 'rcd.noteExistenceStatus' ? (rowData) => this.noteExistsIndicator(rowData.fields[this.getRowDataFieldIndex('rcd.noteExistenceStatus')]) :  this.cellBodyTemplate}
                            sortable={this.isSortable(col[0])}/>;
                        }
                    } else {
                        return null;
                    }
                })
            }
        </DataTable >
    }

    // Show Note exist indicator 
    noteExistsIndicator(noteIndicator) {
        return (
            noteIndicator == "N" ?
                <div className="selection-box note-mrgn">
                    <div className="content-input protect highlight no-label f-text">
                        <div className="size-max resizable-container">
                            <div className="indicators">
                                <div className="indicator">
                                    <span className="highlight-note">
                                       {noteIndicator}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> : ''
        )
    }

    applySort(event:any): void {
        const fieldName = event.sortField.replaceAll("rcd.", "");
        const fieldOrder = event.sortOrder === 1 ? "ASC" : "DESC";

        // test if previous sortby on the same field was set.
        // this allows us to do "ASC", then "DESC", then reset
        const fieldOrderOpposite = event.sortOrder === 1 ? "DESC" : "ASC";
        const eventualPreviousSortBy = `{"${fieldName}":"${fieldOrderOpposite}"}`
        if (fieldOrder === "ASC"
             && this.model['sortData'] === eventualPreviousSortBy){
            this.model['sortData'] = "";
        } else {
            this.model['sortData'] = `{"${fieldName}":"${fieldOrder}"}`;
        }
        this.props.onPageChange({direction: 'reset'});
    }

    getPage(event: any): void {
        // @ts-ignore
        if (this.numRows !== event['rows']) {
            // @ts-ignore
            this.numRows = event['rows'];
            this.model['subfilePageSize'] = event['rows'];
            this.props.onPageChange({direction: 'reset'})
        } else {
            super.getPage(event);
        }
    }

    getIcon(command: string) {
        if(command == "ss") {
            return <CameraIcon style={{ color: '#316BBE' }}/>
        } else if(command == "2") {            
            return <CreateIcon style={{ color: '#316BBE' }}/>
        } else if(command == "3") {
            return <FileCopyIcon style={{ color: '#316BBE' }}/>
        } else if(command == "5") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "6") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "7") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "8") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "9") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "11") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "12") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "13") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "15") {
            return <SearchIcon style={{ color: '#316BBE' }}/>
        } else if(command == "4") {
            return <DeleteIcon style={{ color: '#316BBE' }}/>
        } else if(command == "0") {
            return <NoteIcon style={{ color: '#316BBE' }}/>
        } else if(command == "1") {
            return <AddIcon style={{ color: '#316BBE' }}/>
        } else if(command == "14") {
            return <NoteIcon style={{ color: '#316BBE' }}/>
        }
    }

    /*
    onActionButtonClick(action) {
        console.log(action);
        debugger;
    }
    */

    getMenuItems() {
        // @ts-ignore
        const gridDefinition = this.gridDefinition;
        if (gridDefinition.actions) {
            // @ts-ignore
            let menuItems = gridDefinition.actions.map((action, index) => {
                return {
                    label: <React.Fragment>
                                <div className="icon-label">
                                    {this.getIcon(action.command)}
                                    <div className="action-label">{action.label}</div>
                                </div>
                            </React.Fragment>,
                    icon: action.icon,
                    action: action,
                    command: (e: any) => {
                        if (this.gearMenu && this.gearMenu.current) {
                             this.gearMenu.current.state.rowData.recordSelected = 'Y';
                             let index = this.gearMenu.current.state.rowIndex;
                             index = index % this.numRows;
                             this.props.model['subfile'][index]['recordSelected'] = 'Y';
                        }
                        //const rowData = this.gearMenu.current.state.rowData;
                        //const index = this.props.model['subfile'].length-1;
                        /*try{
                            //this.props.model['subfile'][rowData]['recordSelected'] = 'Y';
                        } catch(e){
                            //this.props.model['subfile'][index]['recordSelected'] = 'Y';
                        }
                        */
                        this.onActionButtonClick(e.item.action);
                    }
                }
            });
            // @ts-ignore
            if (this.selectedRows && this.selectedRows.length > 1 ) {
                menuItems = [{
                    // @ts-ignore
                    label: `Bulk Edit (${this.selectedRows.length} items)`,
                    items: menuItems
                }]
            }
            return menuItems;
        }
        return null;
    }


    getCellFilter(filter: any, index: any):any{
        if(this.model['fields'][filter.field]!==undefined){
            if(filter.type==='string' || filter.type==='long' || filter.type==='number')
            {
                const keyFilter = (filter.type==='number')?'num':'alphanum';
                const textAlign =(filter.type==='number')?'right':'left';
                return(
                    <React.Fragment key={'filter'+index}>
                        {filter.label && filter.label!=='' && 
                        (<label className="filter-label"></label>)}
                        <InputText
                        key={'InputText'+ index}
                        id={filter.field}
                        name={filter.field}
                        placeholder={filter.placeholder}
                        value={this.model['fields']['filter.field']}
                        keyfilter={keyFilter}
                        onChange={(e:any):void =>{
                            let newValue = e.target.value;
                            if(filter.displayFormat && filter.displayFormat.textCase
                                &&filter.displayFormat.textCase.toLowerCase()==='uppercase')
                                {
                                    newValue = newValue.toUpperCase();
                                }
                                this.onFilterValueChange(newValue , filter.field);
                        }}
                        style={{textAlign}}

                        ></InputText>
                    </React.Fragment>
                );
            }
        }
        return super.getCellFilter(filter, index);
    }


    /*
     * Return the current sortField if one is set.
     */
    getSortField(): string | undefined {
        try {
            const sortBy = JSON.parse(this.model['sortData']);
            const attributes = Object.keys(sortBy);
            return "rcd."+attributes[0];
        } catch (e) { }
        return undefined;
    }

    /*
     * Return the current sortOrder if one is set.
     */
    getSortOrder(): DataTableSortOrderType {
        try {
            const sortBy = JSON.parse(this.model['sortData']);
            const attributes = Object.keys(sortBy);
            const order = sortBy[attributes[0]];
            return order === "ASC" ? 1 : -1;
        } catch (e) { }
        return null;
    }

    /*
     * Return true if field is listed in DTO fieldConditions as "sort.XXX"
     */
    isSortable(col:any): boolean {
        return this.model
            && this.model['fieldConditions']
            && this.model['fieldConditions']['sort.'+col.field] === true;
    }

    replyToBackend = (newFormData: any, rowData: any, field: any ) => {
        const terminal = new TerminalCommunicationService();
        this.showLoader();
        // clear all previous record selection fields.
        newFormData["subfile"].forEach(row=> {
            row['recordDataChanged'] = 'N';
        });

        // set F4 for open Prompt.
        newFormData['cmdKey'] = '04';
        // set cursor field
        newFormData['cursorField'] = field;
        rowData['recordDataChanged'] = 'Y'; 
        // set record selection on clicked hyperlink.   
        newFormData['subfile'][rowData["index"]] = rowData;
        // terminal lib need a refresh of its model
        model.next({
          data: newFormData
        });       
        terminal.reply();
      }

  /**
   * Shows the loaders.
   */
  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }

}
