import * as React from 'react';
import { model, TerminalCommunicationService } from '@fresche/terminal-lib';
import { FContinueButton, FNumericInput, FSelectInput, FTextInput, FLabel, FDateTimeInput, FMultilineInput, FGrid } from '@fresche/ui-lib-react';
import { ToastContainer, toast } from 'react-toastify';
import { MegaMenu } from 'primereact/megamenu';
import { Tooltip } from 'primereact/tooltip';
import TextDisplay from '../layout/text-display/text-display';
import 'react-toastify/dist/ReactToastify.css';
import './base.css';
import OptumGrid from "../layout/ui/OptumGrid";
import CustomBase from "./custom-base";
import {Button} from "primereact/button";
import { MultiSelect } from 'primereact/multiselect';
import { getTenantIds } from "../components/programs/TenantList";
import { portunusAbilities } from "../security/AuthProxy";
import SnapshotBase from './SnapshotBase';

declare global {
  interface Window { config: any; }
}

export interface BaseProps {
  type?: string;
  formData: any;
  screenDef: any;
  isActiveScreen: boolean;
}

/**
 *
 */
class BaseComponent<P = {}, S = any> extends CustomBase { 
  protected terminal = new TerminalCommunicationService();

  public inputErrors: any;

  public dateFormat: string;
  public timeSeparator: string;
  public showSeconds = true;
  public funKeyPos: string;
  public legacyMode: boolean;
  public exported = false;
  public conditions: any = {};

  public functionKeys: any;
  public formatedInputs: any[];
  public selectedActionField = 'selected';

  protected focusedField = '';
  protected focusedFieldId = '';
  protected tablerowFocusedField = -1; // 0 = input, >0 = datarow

  protected dataGrid: React.RefObject<FGrid> = React.createRef();
  public keysNavigation = React.createRef();
  protected screenDef: any;
  protected formData: any;
  private isProtected = false;
  private tenants = [];

  public menu: MegaMenu; // = React.createRef();

  /**
   *
   */
  constructor(props: BaseProps) {
    super(props);

    this.dateFormat = window.config['ui'] ? window.config['ui']['dateFormat'] : 'yyyy-mm-dd';
    this.timeSeparator = window.config['ui'] ? window.config['ui']['timeSeparator'] : ':';
    this.showSeconds = window.config['ui'] ? window.config['ui']['showSeconds'] : true;
    this.funKeyPos = window.config['ui'] ? window.config['ui']['funKeyPos'] : 'top';

    this.handleKeyboardEvents = this.handleKeyboardEvents.bind(this);
    this.initErrorMessages = this.initErrorMessages.bind(this);
    this.tableAction = this.tableAction.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.isValid = this.isValid.bind(this);

    this.screenDef = this.props.screenDef;
    this.formData = this.props.formData;

    // Split inputs by rows
    this.formatedInputs = [];
    this.formatedInputs = this.formatInputs(props.screenDef);

    this.inputErrors = {};
    this.state = {
      roles : [],
      showMultiEnvSearch: false,
      disableSaveButton: false
    };
    this.initErrorMessages(this.props);
    this.setConditions(this.props);
    this.setDefaultCursor();

    this.functionKeys = [];
    if (props.screenDef.functionKeys && props.screenDef.functionKeys.length) {
      this.functionKeys = this.formatFunctionKeys();
    }
  }
  componentWillMount(): void {
    if(process.env.REACT_APP_API_ENV == 'local') {
      this.setState({showMultiEnvSearch: true});
    } else {
        const tenant = this.formData.currentTenant;
        console.log('componentWillMount portunusAbilities');
        portunusAbilities(tenant)
        .then((res: any) =>
          res.json())
        .then((data) => {
          console.log('portunusAbilities =', data);
          const roles = data['abilities']['all-abilities'];
          this.setState({roles: roles});
          for(const role in this.state.roles) {
            if(this.state.roles[role].includes("multienv.access.grant")) {
              this.setState({showMultiEnvSearch: true});
              return this.tenants;
            }
          }
        });
    }
  }

  /**
  /**
   *
   */
  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyboardEvents, false);
  }

  /**
   *
   */
  componentDidUpdate(): void {
    super.componentDidUpdate();

    // When we pass from a popup to a view
    if (JSON.stringify(this.screenDef) !== JSON.stringify(this.props.screenDef)) {
      this.formatedInputs = [];
      if (this.props.screenDef.inputs && this.props.screenDef.inputs.length) {
        this.formatedInputs = this.formatInputs(this.props.screenDef);
      }

      this.setDefaultCursor();
      this.initErrorMessages(this.props);
      this.formData = this.props.formData;
      this.screenDef = this.props.screenDef;
      this.setConditions(this.props);

      this.functionKeys = [];
      if (this.props.screenDef.functionKeys && this.props.screenDef.functionKeys.length) {
        this.functionKeys = this.formatFunctionKeys();
      }

      this.forceUpdate();
    } else {
      let needRefreshState = false;
      let needRefreshForError = false;

      if (this.formData.cursorField !== this.props.formData.cursorField) {
        this.formData.cursorField = this.props.formData.cursorField;
        this.setDefaultCursor();
      }

      if (this.formData.cursorTableRow !== this.props.formData.cursorTableRow) {
        this.formData.cursorTableRow = this.props.formData.cursorTableRow;
        this.setDefaultCursor();
      }

      // Because we have a dynamic nested object, we compare each node value
      if (JSON.stringify(this.formData.fields) !== JSON.stringify(this.props.formData.fields)) {
        needRefreshState = true;
      }

      if (JSON.stringify(this.formData['messageDTO']) !== JSON.stringify(this.props.formData['messageDTO'])) {
        needRefreshForError = true;
        needRefreshState = true;
      }

      if (JSON.stringify(this.formData.portunusVerb) !== JSON.stringify(this.props.formData.portunusVerb)) {
        needRefreshState = true;
      }

      if (this.formData['programMode'] !== this.props.formData['programMode']) {
        needRefreshState = true;
      }

      if (this.formData.subfile && this.props.formData.subfile) {
        for (let index = 0; index < this.props.formData.subfile.length; index++) {
          const propsFields = JSON.stringify(this.props.formData.subfile[index]);
          const stateFields = this.formData.subfile[index] ? JSON.stringify(this.formData.subfile[index]) : '';

          const propsErrors = JSON.stringify(this.props.formData.subfile[index].messageDTO);
          const stateError = this.formData.subfile[index] ? JSON.stringify(this.formData.subfile[index].messageDTO) : '';

          if (propsFields !== stateFields) {
            needRefreshState = true;
          }

          if (propsErrors !== stateError) {
            needRefreshForError = true;
          }
        }

        if (this.formData.subfile.length !== this.props.formData.subfile.length) {
          needRefreshState = true;
        }
      }

      if (this.formData.subfileHasAllData !== this.props.formData.subfileHasAllData) {
        needRefreshState = true;
      }

      if (this.props.formData.subfileHasAllData) {
        if (this.formData.subfileTopRRN !== this.props.formData.subfileTopRRN) {
          needRefreshState = true;
        }
      }

      if (this.formData.subfilePageNumber !== this.props.formData.subfilePageNumber) {
        needRefreshState = true;
      }

      if (needRefreshForError) {
        this.initErrorMessages(this.props);
      }

      if (needRefreshState) {
        this.formData = this.props.formData;
        this.setConditions(this.props);
        this.forceUpdate();
      }
    }
    // call a function onLoad to replace TASKnnnnnn as alink
    if (this.screenDef.onLoad && (this as any)[this.screenDef.onLoad]) {
      (this as any)[this.screenDef.onLoad]();
    }
    if (this.props.formData.gridExport && !this.exported) {
      if(this.props.formData.exportSubFileList.length === 0) {
        return;
      } else {
        this.exported = true;
      }
      setTimeout(this.downloadCSV, 1000, this);
    }

  }

  /**
   *
   */
  componentDidMount(): void {
    super.componentDidUpdate();
    document.addEventListener('keydown', this.handleKeyboardEvents, false);
    this.setDefaultCursor();
    if(process.env.REACT_APP_API_ENV != 'local') {
      console.log('componentDidMount');
      getTenantIds().then((res: any) =>
        res.json()).then((data) => {
        console.log('getTenantIds=', data);
          if (data && data.roles && data.roles.length > 0) {
            let tenant = "";
            for(let i=0; i<data.roles.length; i++) {
              tenant = data.roles[i];
              this.tenants.push({value: tenant, label: tenant});
            }
          }
        })
    }
    // call a function onLoad to replace TASKnnnnnn as alink
    if (this.screenDef.onLoad && (this as any)[this.screenDef.onLoad]) {
      (this as any)[this.screenDef.onLoad]();
    }
  }

  /**
   *
   */
  setDefaultCursor(): void {
    setTimeout((): void => {
      let selectors = [
        'input:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'select:not([disabled]):not([readonly]):not([tabindex="-1"])',
        'textarea:not([disabled]):not([readonly]):not([tabindex="-1"])'
      ];

      // If we have a default field in the payload
      if (this.formData && this.formData.cursorField) {
        selectors = [
          `#${this.formData.cursorField}`
        ];
      }

      let rootToSearch: any = document;

      // if we are in a popup dialog then we need to search inside this dialog (not in all DOM)
      if (this.props.type === 'popup') {
        rootToSearch = document.querySelector('div.p-dialog');
      }

      let focusFieldFound = false;

      // If we have a default field in the payload
      if (this.formData && this.formData.cursorField) {
        const cursorField = '#' + this.formData.cursorField.split('.').join('\\.');

        const focusable = rootToSearch.querySelectorAll(cursorField);

        if (focusable.length > 0) {
          const toFocus: any = focusable[0];
          toFocus.focus();
          focusFieldFound = true;
        }
      }

      if (focusFieldFound === false) {
        const selectors = [
          'input:not([disabled]):not([readonly]):not([tabindex="-1"])',
          'select:not([disabled]):not([readonly]):not([tabindex="-1"])',
          'textarea:not([disabled]):not([readonly]):not([tabindex="-1"])',
          '.p-checkbox-box:not([disabled]):not([readonly]):not([tabindex="-1"])'
        ];
        const focusables = rootToSearch.querySelectorAll(selectors.join(','));

        if (focusables.length > 0) {
          let toFocus: any = focusables[0];

          // Check if the control is the SELECT ALL in the grid
          let currentParent = toFocus.parentNode;
          let isSelectAllCheckBox = false;

          while (currentParent !== null && !isSelectAllCheckBox) {
            if (currentParent.className && currentParent.className.indexOf('p-selection-column') > -1 && currentParent.attributes['role'].value !== 'cell') {
              isSelectAllCheckBox = true;
            }
            currentParent = currentParent.parentNode;
          }

          if (isSelectAllCheckBox) {
            if (focusables.length > 1) {
              toFocus = focusables[1];
            }
          }
          toFocus.focus();
        }
      }
    }, 1);
  }

  isVisible(el: any) {
    return (el && el.offsetParent !== null);
  }

  generateDynamicTitle(programMode:any, screenDef:any){
    if(programMode==="ADD"){
      return screenDef.addProgramTitle;
    }else if(programMode==="CHG"){
      return screenDef.editProgramTitle;
    }else if(programMode==="DSP"){
      return screenDef.title
    }
     return screenDef.title
  }
  generateDynamicProgName(programMode:any, screenDef:any){
    if(programMode==="ADD"){
      return screenDef.addProgramName;
    }else if(programMode==="CHG"){
      return screenDef.editProgramName;
    }else if(programMode==="DSP"){
      return screenDef.programName
    }
       return screenDef.programName
  }

  /**
   * @param val Value can be anything (string, numeric, datetime)
   * @param zeroNotAllow if zero value not want consider as valid value then pass as true flag 
   * otherwise by default zero value is not consider as empty value
   * @returns true if the value is not set
   */
  isEmpty(val: any, zeroNotAllow = false): boolean {
    return !!(val === undefined || val === null || val.length <= 0 || (typeof val === 'number' && isNaN(val)) || (zeroNotAllow && val == 0) || val === '0001-01-01');
  }

  /**
   * @param field Field ID to check
   * @returns true if the component has an error
   */
  componentIsInError(field: string): boolean {
    const el = document.getElementById(field);

    // Base on current Fresche component structure we must find the parent of the parent then check if the next component is an error-msg
    if (el && el.parentElement && el.parentElement.parentElement) {
      const nextSiblingComponent = el.parentElement.parentElement.nextElementSibling;

      if (nextSiblingComponent && nextSiblingComponent.className.indexOf('error-msg') >= 0) {
        return this.isVisible(nextSiblingComponent);
      }
    }

    return false;
  }

  /**
  * Checks if all the required visible fields are filled
  */
  isValid() {
    this.inputErrors = {};
    let isFirstElementInError = true;
    let valid = true;

    // Loop on all REQUIRED field
    if (this.screenDef && this.screenDef.inputs) {
      this.screenDef.inputs.forEach((input: any) => {
        if (input.isLabel === false) {
          if (input.required && this.isVisible(document.getElementById(input.field))) {
            if (this.isEmpty(this.formData.fields[input.field], input['zeroNotAllow'])) {
              valid = false;
              this.inputErrors[input.field] = (input.requiredMessage === undefined || input.requiredMessage === '')
                               ?'Value required.':input.requiredMessage;
              if (isFirstElementInError) {
                isFirstElementInError = false;
                document.getElementById(input.field).focus();
              }
            }
          }
        }
      });
    }
    return valid;
  }

  /**
   * initialize the view
   */
  initErrorMessages(props: any): void {
    if (props.formData['messageDTO']) {
      this.handleMessages(props.formData['messageDTO']);
      // Flush messageDTO because its displayed to the user
      props.formData['messageDTO'] = [];
    }

    // validates grid field conditions
    if (props.formData['subfile'] && props.formData['subfile'].length > 0) {
      for (let i = 0; i < props.formData['subfile'].length; i++) {
        const rowData = props.formData['subfile'][i];
        if (rowData['messageDTO']) {
          this.onGridError(rowData['messageDTO']);
          // Flush messageDTO because its displayed to the user
          rowData['messageDTO'] = [];
        }
      }
    }
  }

  /**
   * Handles Keyboard Events
   */
  handleKeyboardEvents(event: KeyboardEvent): void {
    // Replace KEYCODE_TO_FUNCTION_KEY of common lib (typescript compatibility)
    const KEYCODE_TO_FUNCTION_KEY = new Map<string, string>([
      ['F1-shift', 'F13'], ['F2-shift', 'F14'], ['F3-shift', 'F15'], ['F4-shift', 'F16'], ['F5-shift', 'F17'], ['F6-shift', 'F18'],
      ['F7-shift', 'F19'], ['F8-shift', 'F20'], ['F9-shift', 'F21'], ['F10-shift', 'F22'], ['F11-shift', 'F23'], ['F12-shift', 'F24'],
      ['F1-ctrl', 'F25'], ['F2-ctrl', 'F26'], ['F3-ctrl', 'F27'], ['F4-ctrl', 'F28'], ['F5-ctrl', 'F29'], ['F6-ctrl', 'F30'],
      ['F7-ctrl', 'F31'], ['F8-ctrl', 'F32'], ['F9-ctrl', 'F33'], ['F10-ctrl', 'F34'], ['F11-ctrl', 'F35'], ['F12-ctrl', 'F36']
    ]);

    if (this.props && this.screenDef && this.props.isActiveScreen) {
      
      if(event.key === "Enter" && !this.isValid()){
        event.preventDefault();
        event.stopPropagation();
      }
      // Handles function keys
      if (this.screenDef.functionKeys) {
        let keyCode = event.key;

        if (event.shiftKey) {
          keyCode = KEYCODE_TO_FUNCTION_KEY.get(event.key + '-shift');
        } else if (event.ctrlKey) {
          keyCode = KEYCODE_TO_FUNCTION_KEY.get(event.key + '-ctrl');
        }

        // If key is defined in the screen def
        const key = this.screenDef.functionKeys.filter((x: any): any => x.key === keyCode)[0];

        // F4 is always active by default in Synon system with X2E3 BUT in RPG program if F4 is not declared then the call should never be done
        const autoHandlingF4 = window.config['ui']['autoHandlingF4'];

        if (key !== undefined) {
          // Key is found in the screen def so take its command to call the backend
          event.preventDefault();
          this.defaultAction(key.command);
        } else if (keyCode === 'F4' && autoHandlingF4) {
          event.preventDefault();
          this.defaultAction('04');
        } else if (keyCode === 'F12') {
          event.preventDefault();
        } else if (['F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 'F9', 'F10', 'F11', 'F12'].includes(event.key)) {
          event.preventDefault();
        }
      }

      // Handles multipage
      switch (event.key) {
        case 'PageUp':
          const PreviousKey = window.config['keys'] ? window.config['keys']['Previous'] : '28';

          event.preventDefault();
          event.stopPropagation();

          if (this.screenDef.isMultiPage) {
            event.preventDefault();
            event.stopPropagation();
            this.pageChanged({ direction: 'previous' });
          } else if (this.screenDef.gridDefinition) {
            this.dataGrid.current.movePage('previous');
          } else if (this.screenDef.functionKeys.find((k: any): any => k.command === PreviousKey) !== undefined) {
            event.preventDefault();
            event.stopPropagation();
            this.defaultAction(PreviousKey);
          }
          break;
        case 'PageDown':
          const NextKey = window.config['keys'] ? window.config['keys']['Next'] : '27';

          event.preventDefault();
          event.stopPropagation();

          if (this.screenDef.isMultiPage) {
            this.pageChanged({ direction: 'next' });
          } else if (this.screenDef.gridDefinition) {
            this.dataGrid.current.movePage('next');
          } else if (this.screenDef.functionKeys.find((k: any): any => k.command === NextKey) !== undefined) {
            event.preventDefault();
            event.stopPropagation();
            this.defaultAction(NextKey);
          }
          break;
        default:
          break;
      }
    }
  }

  /**
   * Message handler - generate msg for each one contained in the DTO
   */
  handleMessages(msgs: any): void {
    this.inputErrors = {};
    msgs.forEach((e: any): void => {
      // map every error message to a given field name
      if (e.fieldsName && e.fieldsName.length > 0) {
        e.fieldsName.forEach((field: any): void => {
          if (Object.keys(this.props.formData.fields).indexOf(field) > -1) {
            this.inputErrors[field] = e.messageText;
          }
        });
      } else {
        const msg: any = {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => { this.setState({disableSaveButton: false})},
          onOpen: () => { this.setState({disableSaveButton: true}) }
        };
        switch (e.messageType) {
          case 'SNDERRMSG':
            toast.error(e.messageText, msg);
            break;
          case 'SNDINFMSG':
          case 'SNDSTSMSG':
            toast.info(e.messageText, msg);
            break;
          case 'SNDCMPMSG':
            toast.success(e.messageText, msg);
            break;
          default:
            toast.info(e.messageText, msg);
            break;
        }
      }
    });
  }

  // ================================================================
  //  CONDITIONS
  // ================================================================

  /**
   * Sets the conditions object for the current screen.
   */
  setConditions(props: any): void {
    this.manageConditions(props, 'inputs', 'field');
    this.manageConditions(props, 'labels', 'reference');
    this.manageConditions(props, 'functionKeys', 'key');
    this.manageGridConditions(props);
    if(this.props.formData['authorised']){
      this.manageAuthorization(props);
    }
    if(this.screenDef.hasContinueButton !== true){
      if(this.props.formData['hasContinueButton'] == true || (this.props.formData['programMode'] !== 'DSP' && this.props.formData['programMode'] !== 'SEL')){
       // this.screenDef.hasContinueButton = true;
       this.screenDef.hasContinueButton = this.props.formData['hasContinueButton'] ;        
      }
    }
  }

  /**
   * Hide the F6/F11/2/4 buttons and if user has access then it will be enabled
   * @param props
   */
  manageAuthorization(props: any) {
    let editOption = {};
    let editIndex = 0;
    let deleteOption = {};
    let deleteIndex = 0;
    const functionKeysToBeRemoved = [];
    const actionButtonToBeRemoved = [];
    if (this.conditions['functionKeys']["F6"]) this.conditions['functionKeys']["F6"]['hidden'] = true;
    if (this.conditions['functionKeys']["F11"]) this.conditions['functionKeys']["F11"]['hidden'] = true;
    for (let index = 0; index < props.screenDef.functionKeys.length; index++) {
      for (let index2 = 0; index2 < this.props.formData['removeFunctionKeys'].length; index2++) {
        if (this.props.formData['removeFunctionKeys'][index2]=== props.screenDef.functionKeys[index]['key']) {
          functionKeysToBeRemoved.push(props.screenDef.functionKeys[index]);
        }
      }
    }

    functionKeysToBeRemoved.forEach(functionKey => {
      const index = props.screenDef.functionKeys.indexOf(functionKey);
      if (index >= 0) {
        props.screenDef.functionKeys.splice(index, 1);
      }
    });

    if (props.screenDef.gridDefinition && props.screenDef.gridDefinition.actions) {
      for (let index = 0; index < props.screenDef.gridDefinition.actions.length; index++) {
        if (props.screenDef.gridDefinition.actions[index]['key'] == "2") {
          editOption = props.screenDef.gridDefinition.actions[index];
        } else if (props.screenDef.gridDefinition.actions[index]['key'] == "4") {
          deleteOption = props.screenDef.gridDefinition.actions[index];
        }
        for (let index1 = 0; index1 < this.formData['removeActionButtons'].length; index1++) {
          if (props.screenDef.gridDefinition.actions && props.screenDef.gridDefinition.actions[index] &&
            props.screenDef.gridDefinition.actions[index]['key'] === this.formData['removeActionButtons'][index1]) {
            actionButtonToBeRemoved.push(props.screenDef.gridDefinition.actions[index]);
          }
        }
      }
      editIndex = props.screenDef.gridDefinition.actions.indexOf(editOption);
      if (editIndex >= 0) props.screenDef.gridDefinition.actions.splice(editIndex, 1);
      deleteIndex = props.screenDef.gridDefinition.actions.indexOf(deleteOption);
      if (deleteIndex >= 0) props.screenDef.gridDefinition.actions.splice(deleteIndex, 1);

      actionButtonToBeRemoved.forEach(actionButton => {
        const actionButtonINdex = props.screenDef.gridDefinition.actions.indexOf(actionButton);
        if (actionButtonINdex >= 0) {
          props.screenDef.gridDefinition.actions.splice(actionButtonINdex, 1);
        }
      });
    }
    if (this.props.formData['portunusVerb']) {
      if (this.props.formData['portunusVerb']['INSERT'] === 'GRANT') {
        if (this.conditions.length === 0) {
          this.conditions['functionKeys'] = {};
        }
        if (this.conditions['functionKeys'] && this.conditions['functionKeys']["F6"])
          this.conditions['functionKeys']["F6"]['hidden'] = false;
      }
      if (this.props.formData['portunusVerb']['CHMODE'] === 'GRANT') {
        if (this.props.formData['programMode'] === 'DSP') {
          props.screenDef.functionKeys.splice(1, 0, { "key": "chmode", "command": "chmode", "label": "Edit" });
        } else {
          props.screenDef.functionKeys.splice(1, 0, { "key": "chmode", "command": "chmode", "label": "View" });
        }
      }
      if (this.props.formData['portunusVerb']['MODIFY'] === 'GRANT' && editIndex >= 0
        && props.screenDef.gridDefinition
        && props.screenDef.gridDefinition.actions.indexOf(editOption) < 0) {
        props.screenDef.gridDefinition.actions.splice(editIndex, 0, editOption);
      }
      if (this.props.formData['portunusVerb']['DELETE']  === 'GRANT') {
        if (this.conditions.length === 0) {
          this.conditions['functionKeys'] = {};
        }
        if (this.conditions['functionKeys'] && this.conditions['functionKeys']["F11"]) {
          this.conditions['functionKeys']["F11"]['hidden'] = false;
        }
        if (deleteIndex >= 0 && props.screenDef.gridDefinition
           && props.screenDef.gridDefinition.actions.indexOf(deleteOption) < 0) {
          props.screenDef.gridDefinition.actions.splice(deleteIndex, 0, deleteOption);
        }
      }
    }
  }

  /**
   * Apply a condition to a given container if a match is found in the
   * backend's response (formData).
   * @param condition the condition object
   * @param container the object containing the conditions
   */
  applyCondition(props: any, condition: any, container: any): any {
    // Split the conditionField string at every dot found
    if (condition && condition.conditionField) {
      if (this.isActiveCondition(props.formData, condition.conditionField)) {
        // Overwrite/Add the value to the conditions object (initial display)
        container[condition.attribute] = condition.attributeValue;
      }

      if (condition.attribute === 'hidden' && condition.applyToText === false) {
        // Manage labels conditions
        container.applyToText = false;
      }
    }
  }

  /**
   * Finds the condition value in the model.
   * @param obj object in which the condition must be found
   * @param path the path provided that leads to the condition
   */
  isActiveCondition(obj: any, path: string): boolean {
    const p = path.split('.');
    if (p.length > 1) {
      // Keep first index
      const accessor = p[0];
      // If accessor is found in the object
      if (obj[accessor]) {
        // Remove first index from path
        p.shift();
        return obj[accessor][p.join('.')];
      } else {
        // console.error(`Condition path not found in the model: ${path}`);
        return false;
      }
    }
    // console.error(`Condition path not found in the model: ${path}`);
    return false;
  }

  /**
   * Constructs a condition object for a specific element type.
   * {
   *   inputs: {
   *     inputName: {
   *       conditionName: conditionValue
   *     }
   *   }
   * }
   * @param elements The element type (inputs or functionKeys).
   * @param identifier The identifier for the given element.
   */
  manageConditions(props: any, elements: string, identifier: string): void {
    if (elements === 'inputs' || elements === 'functionKeys' || elements === 'labels') {
      if (props.screenDef[elements]) {
        // Initialize the conditions for this element
        this.conditions[elements] = {};
        // Loop every element in the screen
        props.screenDef[elements].forEach((element: any): void => {
          // Initialize an object for this specific element
          this.conditions[elements][element[identifier]] = {};
          // If the element has an initial display
          if (element.initialDisplay) {
            // Loop every display object
            element.initialDisplay.forEach((cond: any): void => {
              // Assign the display value to its attribute in the conditions object
              this.conditions[elements][element[identifier]][cond.attribute] = cond.attributeValue;
            });
          }
          // If the element has display conditions
          if (element.displayConditions) {
            // Loop every conditions
            element.displayConditions.forEach((condition: any): void => {
              this.applyCondition(props, condition, this.conditions[elements][element[identifier]]);
            });
          }
        });
      }
    }
  }

  /**
   * Same function as manageConditions, but targeted for conditions found in the
   * subfile of the DTO provided by the backend.
   */
  manageGridConditions(props: any): void {
    // Initialize the conditions for this grid
    this.conditions.grid = {
      gridSelection: {
        rows: {}
      },
      filters: {},
      actions: {}
    };
    if (props.screenDef.gridDefinition) {
      const gridDefinition = props.screenDef.gridDefinition;

      // Selection conditions
      if (gridDefinition.selectionConditions) {
        // Initial display
        if (gridDefinition.selectionConditions.initialDisplay) {
          gridDefinition.selectionConditions.initialDisplay.forEach((c: any): void => {
            this.conditions.grid.gridSelection[c.attribute] = c.attributeValue;
          });
        }
        // Display conditions
        if (gridDefinition.selectionConditions.displayConditions && props.formData.subfile) {
          gridDefinition.selectionConditions.displayConditions.forEach((c: any): any => {
            if (c && c.conditionField) {
              this.applyCondition(props, c, this.conditions.grid.gridSelection);
              // Check for specific row condition
              props.formData.subfile.forEach((row: any, index: number): void => {
                if (this.isActiveCondition(row, c.conditionField)) {
                  const value = c.attributeValue;
                  if (this.conditions.grid.gridSelection.rows[index.toString()] === undefined) {
                    this.conditions.grid.gridSelection.rows[index.toString()] = {};
                  }
                  this.conditions.grid.gridSelection.rows[index.toString()][c.attribute] = value;
                }
              });
            }
          });
        }
      }

      // Action buttons
      if (gridDefinition.actions) {
        gridDefinition.actions.forEach((a: any): void => {
          this.conditions.grid.actions[a.key] = {};

          if (a.initialDisplay) {
          this.conditions.grid.actions[a.key] = {};
            a.initialDisplay.forEach((c: any): void => {
              this.conditions.grid.actions[a.key][c.attribute] = c.attributeValue;
            });
          }

          if (a.displayConditions) {
            // this.conditions.grid.actions[a.key] = {};
            a.displayConditions.forEach((c: any): void => {
              this.applyCondition(props, c, this.conditions.grid.actions[a.key]);
            });
          }
        });
      }

      // Retrieve all columns
      const columns = gridDefinition.columns;
      // Loop all columns
      columns.forEach((column: any): void => {
        // Initialize an array of conditions for this specific column
        let columnConditions = {};
        // Loop every cell of a column
        column.forEach((cell: any): void => {
          // Keep for backwards compatibility with f-grid-cell (must have 'editable' property)
          cell.editable = true;
          // Initialize a conditions object for this specific cell
          const cellConditions: any = {
            rows: {}
          };
          // If the column has an initial display
          if (cell.initialDisplay) {
            // Loop every display object
            cell.initialDisplay.forEach((cond: any): void => {
              // Assign the display value to its attribute in the conditions object
              cellConditions[cond.attribute] = cond.attributeValue;
            });
            columnConditions = cellConditions;
          }
          // If the column has display conditions
          if (cell.displayConditions && props.formData.subfile) {
            // Loop every conditions
            cell.displayConditions.forEach((condition: any): void => {
              if (condition && condition.conditionField) {
                this.applyCondition(props, condition, cellConditions);
                // Check for single cell conditions
                props.formData.subfile.forEach((row: any, index: number): void => {
                  if (this.isActiveCondition(row, condition.conditionField)) {
                    const value = condition.attributeValue;
                    if (cellConditions.rows[index.toString()] === undefined) {
                      cellConditions.rows[index.toString()] = {};
                    }
                    cellConditions.rows[index.toString()][condition.attribute] = value;
                  }
                });
              }
            });
          }
          // If cell has filters
          if (cell.filters && cell.filters.length) {
            cell.filters.forEach((filter: any): void => {
              // Create object to store conditions for this filter
              if (this.conditions.grid.filters[filter.field] === undefined) {
                this.conditions.grid.filters[filter.field] = {};
              }
              // Initial display
              if (filter.initialDisplay) {
                filter.initialDisplay.forEach((condition: any): void => {
                  this.conditions.grid.filters[filter.field][condition.attribute] = condition.attributeValue;
                });
              }
              // Display Conditions
              if (filter.displayConditions) {
                filter.displayConditions.forEach((condition: any): void => {
                  this.applyCondition(props, condition, this.conditions.grid.filters[filter.field]);
                });
              }
            });
          }
        });
        this.conditions.grid[column[0].field] = columnConditions;
      });
    }
  }

  /**
   * Returns the value of an attribute for a specific element.
   * @param container object containing conditions
   * @param inputID the ID of the element (field name)
   * @param attribute the condition attribute
   */
  getCondition(container: any, inputID: string, attribute: string): any {
    if (container && container[inputID]) {
      return container[inputID][attribute];
    }
  }

  /**
   * Checks if an input is hidden but its label isn't
   * @param inputID the ID of the input (field name)
   */
  alwaysVisibleLabel(inputID: any): boolean {
    if (this.conditions && this.conditions.inputs && this.conditions.inputs[inputID]) {
      const condition = this.conditions.inputs[inputID];
      // Returns true if input is hidden but label is not
      return condition.hidden === true && condition.applyToText !== undefined && condition.applyToText === false;
    }
    return false;
  }

  // ================================================================
  //  TOOLBAR
  // ================================================================

  getToolbarClasses(): string {
    const classes = [];
    if (this.props.formData && this.props.formData.modalScreen) {
      classes.push('popup-keys');
    }
    if (this.funKeyPos === 'bottom') {
      classes.push('bottom');
    }
    return classes.join(' ');
  }

  // ================================================================
  //  INPUTS
  // ================================================================

  /**
   * Returns the CSS classes defining the item.
   */
  getItemClasses(item: any): string {
    const classes: string[] = ['col'];

    if (item) {
      if(item.fieldSize){
        classes.push('col-' + item.fieldSize);
      }
      else if (item.size) {
        classes.push('col-' + item.size);
      }

      if (item.type === 'label' || item.isLabel) {
        classes.push('label-col');
      }

      if (!item.label) {
        classes.push('no-label');
      }

      if (item.displayFormat) {
        if (item.displayFormat.textAlign) {
          classes.push(`align-${item.displayFormat.textAlign}`);
        }
      }

      if (item.addClass) {
        item.addClass.forEach((c: string) => {
          classes.push(c);
        });
      }
    }

    return classes.join(' ').toString();
  }

  /**
   * Splits inputs into rows, based on 'y' positionning.
   */
  formatInputs(screenDef: any): any[] {
    const localInput = screenDef.inputs ? JSON.parse(JSON.stringify(screenDef.inputs)) : [];

    // First, convert new label component and add it to inputs
    if (screenDef.labels && screenDef.labels.length > 0) {
      screenDef.labels.forEach((label: any) => {
        const newLabel = JSON.parse(JSON.stringify(label));
        newLabel.type = 'label';
        if (localInput.indexOf(newLabel) === -1) {
          // Add fake property (transform reference into field just to render properly)
          newLabel.field = newLabel.reference;
          localInput.push(newLabel);
        }
      });

      // Re-order based on pos.y and pos.x
      localInput.sort(function (a: any, b: any) {
        if (a.pos.y < b.pos.y) {
          return -1;
        } else if (a.pos.y > b.pos.y) {
          return 1;
        } else {
          // equal then we check the x
          if (a.pos.x < b.pos.x) {
            return -1;
          } else if (a.pos.x > b.pos.x) {
            return 1;
          }
          return 0;
        }
      });
    }

    const rows: any[] = [[]];
    let numRows = 1;
    let currentRow = (screenDef.inputs && screenDef.inputs.length) ? screenDef.inputs[0].pos.y : 0;
    // split inputs by rows
    localInput.forEach((input: any) => {
      const row = input.pos.y;
      if (currentRow !== row) {
        rows.push([]);
        currentRow = row;
        numRows++;
      }

      // If a grid exists in the page then we must check if field is before (99.9%) or after the grid (0.01%).
      // To do that, we just check if the Y position is greater or equal than 20 (because a grid, mostly occupied 10 rows and the latest (14 to 24))
      if (screenDef.gridDefinition) {
        if (input.pos.y >= 20) {
          input.pos.displayAtTheBottom = true;
        }
      }

      input.pos.y = numRows;

      rows[rows.length - 1].push(input);
    });

    return rows;
  }

  // ================================================================
  //  FUNCTION KEYS
  // ================================================================
  /**
   * Formats function keys to support grouping.
   */
  formatFunctionKeys(): any {
    const keys: any[] = [];

    if (this.props.screenDef.functionKeys && this.props.screenDef.functionKeys.length) {
      this.props.screenDef.functionKeys.forEach((functionKey: any): void => {
        // Get condition related attributes
        const conditions = this.conditions.functionKeys[functionKey.key];
        const hidden = conditions && conditions.hidden;
        const label = (conditions && conditions.label) ? conditions.label : functionKey.label;
        const disabled = conditions && conditions.protect;
        const key = functionKey.key;
        if (!hidden) {
          if (functionKey.group && functionKey.group !== 
            '' && window.config['ui']['funKeyGroups']) {
            // Get group of name
            let group = keys.find((obj: any): any => obj.label === functionKey.group);
            // Add group if it doesn't exist
            if (!group) {
              /* eslint-disable */
              keys.push({
                label: functionKey.group,
                items: [[]],
                template: (item: any, _options: any): any => {
                  return (
                    <span data-pr-tooltip={key}>{item.label}</span>
                  );
                }
              });
              /* eslint-enable */
            }
            // Get group of name again
            group = keys.find((obj: any): any => obj.label === functionKey.group);

            // Add item to group
            /* eslint-disable */
            group.items[0].push(
              {
                label: key, // Not displayed but fix an error about unique key for object in React
                items: [{
                  label,
                  disabled,
                  command: (): any => {
                    this.defaultAction(functionKey.command);
                  },
                  template: (item: any, options: any): any => {
                    return (
                      <React.Fragment>
                        <Tooltip target=".custom-tooltip-submenuitem" position="top" />
                        <a href="#" className={`p-menuitem-link custom-tooltip-submenuitem ${functionKey.addClass ? functionKey.addClass.join(' ') : ''}`} role="menuitem" aria-haspopup="false" data-pr-tooltip={key} target={item.target} onClick={options.onClick}>
                          {functionKey.image && (<img src={functionKey.image} className="custom-image" />)}
                          <div className="fcnkey-label">{item.label}</div>
                          <div className="fcnkey-key">{key}</div>
                        </a>
                      </React.Fragment>
                    );
                  }
                }]
              }
            );
            /* eslint-enable */
          } else {
            /* eslint-disable */
            keys.push({
              label,
              disabled,
              command: (): any => {
                this.defaultAction(functionKey.command);
              },
              template: (item: any, _options: any): any => {
                return (
                  <React.Fragment>
                    <Tooltip target=".custom-tooltip-functionkey" position="top" />
                    {functionKey.image && (<img src={functionKey.image} className="custom-image" />)}
                    <span data-pr-tooltip={key} className={`custom-tooltip-functionkey ${functionKey.addClass ? functionKey.addClass.join(' ') : ''}`}>{item.label}</span>
                  </React.Fragment>
                );
              }
            });
            /* eslint-enable */
          }
        }
      });
    }

    return keys;
  }

  /**
   * Returns the options with translated labels
   */
  getDropdownValues(options: any, field: string): any {
    // First if dropdownOptions are in the model for this field
    if (this.formData && this.formData['dropdownOptions'] && this.formData['dropdownOptions'][field]) {
      // Clear options (if they are declared in screen definition)
      options = [];

      // Add to options
      this.formData['dropdownOptions'][field].forEach((option: any, index: number): void => {
        options.push({ value: option.code, label: option.description });
      });
    }

    return options;
  }

  /**
   * Event called when an input is focused
   */
  onFieldFocus(fieldName: string, shortFieldName: string): void {
    this.focusedField = fieldName;
    this.focusedFieldId = shortFieldName;
    // row zero means no row in datatable
    this.tablerowFocusedField = -1;
  }

  getRightLabelClass(input: any): string {
    return 'right-label col col-' + (input.size + input.label.length + 1);
  }

  // ================================================================
  //  GRID
  // ================================================================

  /**
   * Grid event on field focus
   */
  onTableFieldFocus(fieldName: string, shortFieldName: string, rowNumber: number): void {
    this.focusedField = fieldName;
    this.focusedFieldId = shortFieldName;
    this.tablerowFocusedField = rowNumber % this.props.formData['subfilePageSize'];
  }

  /**
   * code on table action buttons, setprogramMode if delete, and
   * affect recordDataChanged and selected of selected rows..
   * and call reply
   */
  tableAction(action: any): void {
    this.showLoader();

    if (action.useItAsFunctionKey) {
      this.defaultAction(action.command);
    } else {
      const replyFormData = this.formData;
      for (let i = 0; i < replyFormData['subfile'].length; i++) {
        if (replyFormData['subfile'][i]['recordSelected'] === 'Y') {
          replyFormData['subfile'][i]['recordDataChanged'] = 'Y';
          replyFormData['subfile'][i][this.selectedActionField] = action.command;
        }
      }

      // if(tenant != undefined) {
      //   this.updateTenant(tenant);
      // }

      replyFormData['gridAction'] = action.command;
      replyFormData['cmdKey'] = '00';

      this.replyToBackend(replyFormData);
    }
  }

  /**
   * after page change, sent reply
   * Event:
   *    pageOfPages: boolean,
   *    direction: 'next' or 'previous',
   *    pageNumber: page to display),
   *    pageSize: total of record in the page
   */
  pageChanged(event: any): void {
    // if page has a grid and is the active page
    this.showLoader();

    const replyFormData = this.formData;
    if (event.pageOfPages) {
      // When page of pages feature is active, the we send 00 and the page
      replyFormData['cmdKey'] = "00";
      replyFormData['subfilePageNumber'] = event.pageNumber;
    } else {
      if (event.direction === 'previous') {
        replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['PageUp'] : '07';
      } else if (event.direction === 'next') {
        replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['PageDown'] : '08';
      } else if (event.direction === 'reset') { 
        replyFormData['cmdKey'] = window.config['keys'] ? window.config['keys']['Reset'] : '05';
      }
    }

    this.replyToBackend(replyFormData);
  }

  /**
   * Returns if the page should show the grid, based on the
   * 'screenControl' variables passed from the DTO.
   */
  showGrid(): boolean {
    return this.props.formData && (this.props.formData['screenControl'] === undefined || this.props.formData['screenControl'] !== 'H');
  }

  /**
   * returns the max length of a filter based on its definitions
   */
  getFilterMaxLength(column: any, filter: any): number {
    if (filter.displayFormat && filter.displayFormat.textLength !== undefined && filter.displayFormat.textLength > 0) {
      return filter.displayFormat.textLength;
    } else if (column.displayFormat && column.displayFormat.textLength) {
      return column.displayFormat.textLength;
    } else if (filter.textLength !== undefined && filter.textLength > 0) {
      return filter.textLength;
    } else if (column.textLength) {
      return column.textLength;
    }
  }

  /**
   * Grid Message handler - generate msg for each one contained in the DTO (duplicate message appearing on the grid in the toast messager)
   */
  onGridError(event: any): void {
    event.forEach((e: any): any => {
      toast.error((e.messageText + ' - ' + e.fieldsName.join(',')), {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    });
  }

  // ================================================================
  //  BACKEND
  // ================================================================

  /**
   * code called from functionkeys
   */
  promptAction(fieldName: string, shortFieldName: string): void {
    this.showLoader();
    const replyFormData = this.formData;
    const command = '04'; // prompt command number = 04

    replyFormData['cursorField'] = fieldName;
    if (replyFormData['cursorFieldId'] !== undefined) {
      replyFormData['cursorFieldId'] = shortFieldName;
    }
    replyFormData['cursorTableRow'] = this.tablerowFocusedField;
    replyFormData['cmdKey'] = command;

    this.replyToBackend(replyFormData);
  }

  /**
   * code called from functionkeys
   */
  defaultAction(command: string): void {
    const replyFormData = this.formData;

    this.showLoader();

    replyFormData['cursorField'] = this.focusedField;
    if (replyFormData['cursorFieldId'] !== undefined) {
      replyFormData['cursorFieldId'] = this.focusedFieldId;
    }
    replyFormData['cursorTableRow'] = this.tablerowFocusedField;

    replyFormData['cmdKey'] = command;
    this.replyToBackend(replyFormData);
  }

  /**
   * When the user click on continue button
   */
  onSubmit(): void {
    if (this.isValid()) {
      this.showLoader();

      const replyFormData = this.formData;

      replyFormData['cursorField'] = this.focusedField;
      if (replyFormData['cursorFieldId'] !== undefined) {
        replyFormData['cursorFieldId'] = this.focusedFieldId;
      }
      replyFormData['cursorTableRow'] = this.tablerowFocusedField;

      replyFormData['cmdKey'] = '00';
      replyFormData['gridExport'] = false;
      this.replyToBackend(replyFormData);
    } else {
      // To display error messages
      this.forceUpdate();
    }
  }

  convertArrayOfObjectsToCSV(array: any[]): any  {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';

    const keyObjs = this.screenDef.gridDefinition.columns.map( x=> {
      return x.length > 0 ? { key: x[0].field, header: x[0].header1} : {};
    })
    console.log('keyObjs=', keyObjs);

    const headers =keyObjs.map(h => h.header);
    const keys =keyObjs.map(h => h.key);
    const map = new Map<string, any>(this.formData.subfileFields.map((obj) => [obj.field, obj.index])); 
    result = '';
    result += headers.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        result += (item.fields[map.get(key)] + '').replace(',', ' ');
        // eslint-disable-next-line no-plusplus
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV(thisObj: any): void {
    console.log('myJsonDataArray=', thisObj.props.formData.exportSubFileList);

    const csv: any = thisObj.convertArrayOfObjectsToCSV(thisObj.props.formData.exportSubFileList);
    if (csv == null) return;
    let exportedFilename: any;
    if (thisObj.screenDef.showReport) {
      exportedFilename = thisObj.screenDef.reportName + '.csv' || 'export.csv';
    } else {
      exportedFilename = thisObj.screenDef.programName + '.csv' || 'export.csv';
    }
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilename)
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', exportedFilename)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }

  onGridSearchSubmit(): void {
        this.exported = false;
        this.showLoader();
        const replyFormData = this.formData;
        replyFormData['cursorField'] = this.focusedField;
        if (replyFormData['cursorFieldId'] !== undefined) {
            replyFormData['cursorFieldId'] = this.focusedFieldId;
        }
        replyFormData['cursorTableRow'] = this.tablerowFocusedField;
        replyFormData['cmdKey'] = '00';
        replyFormData['gridExport'] = true;
        model.next({
            data: replyFormData
        });
        this.terminal.reply();
    }

  /**
   * Because we need to set data for terminal lib
   */
  replyToBackend(newFormData: any): void {
    newFormData = this.addSubfileTopRRN(newFormData);
    newFormData = this.removeUselessDataFromPayload(newFormData);

    this.terminal.reply();
  }

  /**
   * If applicable, add to metadata RRN assigned to the top row in the grid
   */
  addSubfileTopRRN(newFormData: any) {
    const replyFormData = newFormData;

    if (replyFormData && replyFormData['subfile'] && replyFormData['subfileHasAllData']) {
      if (replyFormData['subfile'].length > 0) {
        const topRRN = this.dataGrid.current.getTopRRN();

        if (topRRN) {
          replyFormData['subfileTopRRN'] = topRRN;
        }
      }
    }

    return replyFormData;
  }

  /**
   *
   */
  uselessFieldIsProtectedByProtectedCondition(input: any): boolean {
    let result = false;

    if (input.displayConditions) {
      input.displayConditions.forEach((c: any): void => {
        if (c.attributeValue && c.attribute === 'protect') {
          result = true;
        }
      });
    }

    return result;
  }

  /**
   * 
   * Get if the attribute is readonly
   */
  isReadOnly(input:any, formData:any): boolean {
    let readOnly = false;

    if(input?.initialDisplay) {
      for(const i in input.initialDisplay) {
        if(input.initialDisplay[i].attribute === "protect") {
          if(input.initialDisplay[i].attributeValue){
            readOnly = true;
          }
        }
      }
    }
    if(input.initialDisplay) {
      for(const i in input.displayConditions) {
        if(input.displayConditions[i].attribute === "protect") {
          if(this.formData.fieldConditions[input.field]) {
            readOnly = true;
          }
        }
      }
    }
 
    if(formData.DataprogramMode == "DSP") {
      readOnly = true;
    }
    return readOnly;
  }

  /**
   *
   */
  removeUselessDataFromPayload(newFormData: any): any {
    const replyFormData = newFormData;

    if (replyFormData) {
      // 1) Remove read-only Inputs (Initial display HIDDEN / PROTECT, displayCondition: HIDDEN / PROTECT)
      const uselessFields: any[] = [];
      // Get all useless fields
      if (this.screenDef.inputs) {
        this.screenDef.inputs.forEach((input: any) => {
          if (input.initialDisplay) {
            input.initialDisplay.forEach((c: any): void => {
              if (c.attributeValue && (c.attribute === 'protect' || c.attribute === 'hidden')) {
                if (this.uselessFieldIsProtectedByProtectedCondition(input) === false) {
                  uselessFields.push(input.field);
                }
              }
            });
          }
          if (input.displayConditions) {
            input.displayConditions.forEach((c: any): void => {
              if (c.attributeValue && (c.attribute === 'hidden') && this.isActiveCondition(replyFormData, c.conditionField)) {
                uselessFields.push(input.field);
              }
            });
          }
        });
      }

      if (uselessFields.length > 0 && replyFormData.fields) {
        uselessFields.forEach((field: any): void => {
          const idx = Object.keys(replyFormData.fields).indexOf(field);
          if (idx > -1) {
            delete replyFormData.fields[field];
          }
        });
      }

      // 2) Remove fieldConditions and functionKeyConditions
      if (replyFormData['fieldConditions']) {
        replyFormData['fieldConditions'] = {};
      }

      if (replyFormData['functionKeyConditions']) {
        replyFormData['functionKeyConditions'] = {};
      }

      // 3) Remove read-only subfile field (Initial display HIDDEN / PROTECT, displayCondition: HIDDEN / PROTECT)
      if (replyFormData['subfile']) {
        const uselessSubfileFields: any[] = [];
        // Get all useless fields
        if (this.screenDef.gridDefinition && this.screenDef.gridDefinition.columns) {
          this.screenDef.gridDefinition.columns.forEach((col: any) => {
            // We check for the first field in the column (a column can have multiple field in a cell)
            if (col[0].initialDisplay) {
              col[0].initialDisplay.forEach((c: any): void => {
                if (c.attributeValue && (c.attribute === 'protect' || c.attribute === 'hidden')) {
                  if (this.uselessFieldIsProtectedByProtectedCondition(col[0]) === false) {
                    uselessSubfileFields.push(col[0].field);
                  }
                }
              });
            }
            if (col[0].displayConditions) {
              col[0].displayConditions.forEach((c: any): void => {
                if (c.attributeValue && (c.attribute === 'hidden') && this.isActiveCondition(replyFormData['subfile'], c.conditionField)) {
                  uselessSubfileFields.push(col[0].field);
                }
              });
            }
          });
        }

        if (uselessSubfileFields.length > 0 && replyFormData.subfile) {
          uselessSubfileFields.forEach((field: any): void => {
            // for each row
            replyFormData.subfile.forEach((row: any) => {
              // for each field
              const idx = Object.keys(row.fields).indexOf(field);
              if (idx > -1) {
                delete row.fields[field];
              }
            });
          });
        }
      }
    }
    return replyFormData;
  }

  // ================================================================
  //  UI
  // ================================================================

  /**
   * Shows the loaders.
   */
  showLoader(): void {
    const loaderElement = document.getElementById('loaderModal');
    if (loaderElement) {
      loaderElement.classList.add('show');
    }
  }
   getTopButtons() {
    const topFunctions = [];
    for(const func in this.functionKeys) { 
      if(this.screenDef.gridDefinition && this.formData.subfile){
        if(this.functionKeys[func].label.includes("Back") || 
        this.functionKeys[func].label.includes("View") ||
        this.functionKeys[func].label == "Note" ||
        this.functionKeys[func].label.includes("Suppress Update") ||
        this.functionKeys[func].label.includes("Edit")) {
          topFunctions.push(this.functionKeys[func]);
        }
      }else{
        topFunctions.push(this.functionKeys[func]);
      }
    }
    return topFunctions;
  }

  getBottomButtons() {
    const bottomButtons = [];
    for(const func in this.functionKeys) { 
      if(this.functionKeys[func].label.includes("Back") || 
      this.functionKeys[func].label.includes("View") ||
      this.functionKeys[func].label == "Note" ||
      this.functionKeys[func].label.includes("Suppress Update") ||
      this.functionKeys[func].label.includes("Edit")) {
      } else {
        bottomButtons.push(this.functionKeys[func]);
      }
    }
    return bottomButtons;
  }

  /**
   *
   */
  render(): React.ReactElement {
    this.getTopButtons();
    if (this.screenDef.hasTopButtons == null) {
      this.screenDef.hasTopButtons = true;
    }

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={50000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnHover={true}
        />
        {
          this.screenDef && (
            <div className="content-container clearfix">
              {/* <!-- TOOLBAR --> */}
                  {this.screenDef.hasTopButtons && 
                <div className="top-buttons">
                  <div id="toolbar" className={this.getToolbarClasses()}>
                    <div className="function-keys">
                      {this.functionKeys && (
                        <MegaMenu ref={(el: any): any => this.menu = el} model={this.getTopButtons()} />
                      )}
                    </div>
                  </div>
                </div>              
              }
              {this.screenDef.hasTopButtons && 
                <div id="toolbar" className={this.getToolbarClasses()}>
                  <div className="right-buttons" style={{display: this.screenDef.hasContinueButton === true ? '' : 'none'}}>
                    <FContinueButton
                      id="continue-button"
                      title="Save"
                      handleContinue={(): void => this.onSubmit()}
                      disabled={!this.props.isActiveScreen}
                    />
                  </div >
                </div>
              }
              
              {/* PROGRAM NAME */}
              <div className="container-fluid app-title-header">              
                {(this.formData.modalScreen && this.screenDef.snapshotScreen) ? 
                  <h1>{this.screenDef.title}</h1> :
                  <h1>{this.screenDef.isDynamicTitle ? this.generateDynamicTitle(this.formData.programMode, this.screenDef) : this.screenDef.title} - <span>{this.screenDef.isDynamicProgramName ? this.generateDynamicProgName(this.formData.programMode, this.screenDef) : this.screenDef.programName}</span></h1>
                }
              </div>
              {/* <!------- SNAPSHOT SCREENS -------> */}
              { this.formData.modalScreen  && this.screenDef.snapshotScreen && 
                  <SnapshotBase formData={this.formData} programName={this.screenDef.programName}></SnapshotBase> }
              {/* <!-- INPUTS (displayAtTheBottom is false, normal display) --> */}
              {this.formatedInputs && (
                <div className="container-fluid">
                  <form>
                    {this.formatedInputs.map((row: any, rowindex: any): any => (
                      <div className={`${this.screenDef.removeGrid ? 'row1' : 'row'}`} key={rowindex}>
                        {row.filter((obj: any): any => { return obj.pos.displayAtTheBottom === undefined; }).map((input: any, index: any): any => (
                          <React.Fragment key={index}>
                            {/* <!-- LABEL OBSOLETE (X2E3) --> */}
                            {input.isLabel && (
                              <div className={this.getItemClasses(input)}>
                                <FLabel
                                  id={input.field}
                                  name={input.field}
                                  text={input.label}
                                  size={input.size}
                                  color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                  readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                  highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                  protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                  reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                  underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                  hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                />
                              </div>
                            )}
                            {!input.isLabel && (
                              <div className={this.getItemClasses(input)}>
                                {/* <!-- NEW LABEL (XM3) --> */}
                                {input.type === 'label' && (
                                  <FLabel
                                    id={input.field}
                                    name={input.field}
                                    text={input.label}
                                    size={input.size}
                                    color={this.getCondition(this.conditions['labels'], input.field, 'color')}
                                    readonly={this.getCondition(this.conditions['labels'], input.field, 'readonly')}
                                    highintensity={this.getCondition(this.conditions['labels'], input.field, 'highintensity')}
                                    protect={this.getCondition(this.conditions['labels'], input.field, 'protect')}
                                    reverseimage={this.getCondition(this.conditions['labels'], input.field, 'reverseimage')}
                                    underline={this.getCondition(this.conditions['labels'], input.field, 'underline')}
                                    hidden={this.getCondition(this.conditions['labels'], input.field, 'hidden')}
                                  />
                                )}
                                {/* <!-- TEXT --> */}
                                {input.type === 'string' && (!this.isReadOnly(input, this.formData) ?
                                  <FTextInput
                                    id={input.field}
                                    name={input.field}
                                    size={input.size}
                                    placeholder={input.placeholder}
                                    value={this.formData['fields'][input.field]}
                                    label={input.labelLocation === 'above' ? input.header1 : input.label}
                                    maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                    required={input.required}
                                    error={this.inputErrors[input.field]}
                                    displayFormat={input.displayFormat}
                                    onValueChange={(newValue: any): void => {
                                      const formData = this.formData;
                                      formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                      this.forceUpdate();
                                    }}
                                    promptable={input.lookupFlag}
                                    onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                    suffix={input.options ? input.options.suffix : null}
                                    labelOnTop={input.labelLocation === 'above'}
                                    onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                    onBlur={(): void => { }}
                                    color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                    readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                    highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                    protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                    reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                    underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                    hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    alwaysVisibleLabel={this.alwaysVisibleLabel(input.field)}
                                  />  : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                )}
                                {/* <!-- MULTILINE --> */}
                                {input.type === 'multiline' && (!this.isReadOnly(input, this.formData) ?
                                  <FMultilineInput
                                    id={input.field}
                                    name={input.field}
                                    size={input.size}
                                    value={this.formData['fields'][input.field]}
                                    label={input.labelLocation === 'above' ? input.header1 : input.label}
                                    maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                    required={input.required}
                                    error={this.inputErrors[input.field]}
                                    displayFormat={input.displayFormat}
                                    onValueChange={(newValue: any): void => {
                                      const formData = this.formData;
                                      formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                      this.forceUpdate();
                                    }}
                                    promptable={input.lookupFlag}
                                    onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                    suffix={input.options ? input.options.suffix : null}
                                    labelOnTop={input.labelLocation === 'above'}
                                    onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                    onBlur={(): void => { }}
                                    color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                    readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                    highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                    protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                    reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                    underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                    hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    alwaysVisibleLabel={this.alwaysVisibleLabel(input.field)}
                                  /> : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                )}
                                {/* <!-- NUMERIC --> */}
                                {input.type === 'number' && (!this.isReadOnly(input, this.formData) ?
                                  < FNumericInput
                                    id={input.field}
                                    name={input.field}
                                    placeholder={input.placeholder}
                                    size={input.size}
                                    value={this.formData['fields'][input.field]}
                                    label={input.labelLocation === 'above' ? input.header1 : input.label}
                                    maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                    required={input.required}
                                    error={this.inputErrors[input.field]}
                                    displayFormat={input.displayFormat}
                                    onValueChange={(newValue: any): void => {
                                      const formData = this.formData;
                                      formData['fields'][input.field] = newValue;
                                      this.forceUpdate();
                                    }}
                                    promptable={input.lookupFlag}
                                    onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                    suffix={input.options ? input.options.suffix : null}
                                    labelOnTop={input.labelLocation === 'above'}
                                    onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                    onBlur={(): void => { }}
                                    color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                    readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                    highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                    protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                    reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                    underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                    hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    alwaysVisibleLabel={this.alwaysVisibleLabel(input.field)}
                                  />  : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                )}
                                {/* <!-- DATE & TIME --> */}
                                {(input.type === 'datetime' || input.type === 'date' || input.type === 'time') && (!this.isReadOnly(input, this.formData) ?
                                  <FDateTimeInput
                                    id={input.field}
                                    type={input.type}
                                    size={input.size}
                                    dateFormat={this.dateFormat}
                                    timeSeparator={this.timeSeparator}
                                    showSeconds={this.showSeconds}
                                    name={input.field}
                                    value={this.formData['fields'][input.field]}
                                    label={input.labelLocation === 'above' ? input.header1 : input.label}
                                    maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                    required={input.required}
                                    error={this.inputErrors[input.field]}
                                    displayFormat={input.displayFormat}
                                    onValueChange={(newValue: any): void => {
                                      const formData = this.formData;
                                      formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                      this.forceUpdate();
                                    }}
                                    promptable={input.lookupFlag}
                                    onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                    suffix={input.options ? input.options.suffix : null}
                                    labelOnTop={input.labelLocation === 'above'}
                                    onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                    onBlur={(): void => { }}
                                    color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                    readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                    highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                    protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                    reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                    underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                    hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    alwaysVisibleLabel={this.alwaysVisibleLabel(input.field)}
                                  />
                                  : <TextDisplay input={input} formData={this.formData}></TextDisplay>
                                )}
                                {input.type === "search-dropdown" && (!this.isReadOnly(input, this.formData) ?
                                    <FSelectInput
                                      id={input.field}
                                      customValue={input.customValue}
                                      name={input.field}
                                      size={input.size}
                                      values={this.getDropdownValues(input.options, input.field)}
                                      value={this.formData['fields'][input.field]}
                                      label={input.labelLocation === 'above' ? input.header1 : input.label}
                                      maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                      required={input.required}
                                      error={this.inputErrors[input.field]}
                                      displayFormat={input.displayFormat}
                                      onValueChange={(newValue: any): void => {
                                        const formData = this.formData;
                                        formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                        for (let i = 0; i < formData['subfile'].length; i++) {
                                          formData['subfile'][i]['recordDataChanged'] = 'N';
                                        }
                                        formData['cmdKey'] = "00";
                                        this.forceUpdate();
                                        this.showLoader();
                                        this.replyToBackend(formData);
                                      }}
                                      autofocus={this.formData['cursorField'] === input.field}
                                      promptable={input.lookupFlag}
                                      onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                      suffix={input.options ? input.options.suffix : null}
                                      labelOnTop={input.labelLocation === 'above'}
                                      onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                      onBlur={(): void => { }}
                                      color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                      readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                      highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                      protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                      reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                      underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                      hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                      alwaysVisibleLabel={this.alwaysVisibleLabel(input.field)}
                                    />
                                    : <TextDisplay input={input} formData={this.formData} dropdownValue={this.getDropdownValues(input.options, input.field)}></TextDisplay>
                                  )
                                  }
                                {/* <!-- SELECT --> */}
                                {input.type === 'dropdown' && (!this.isReadOnly(input, this.formData) ?
                                  <FSelectInput
                                    id={input.field}
                                    name={input.field}
                                    size={input.size}
                                    values={this.getDropdownValues(input.options, input.field)}
                                    value={this.formData['fields'][input.field]}
                                    label={input.labelLocation === 'above' ? input.header1 : input.label}
                                    maxlength={input.displayFormat && input.displayFormat.textLength ? input.displayFormat.textLength : input.textLength}
                                    required={input.required}
                                    error={this.inputErrors[input.field]}
                                    displayFormat={input.displayFormat}
                                    onValueChange={(newValue: any): void => {
                                      const formData = this.formData;
                                      formData['fields'][input.field] = input.displayFormat && input.displayFormat.textCase && input.displayFormat.textCase === 'uppercase' ? newValue.toUpperCase() : newValue;
                                      this.forceUpdate();
                                    }}
                                    promptable={input.lookupFlag}
                                    customValue={input.customValue}
                                    onPrompt={input.lookupFlag ? (): void => this.promptAction(input.field, input.fieldId) : null}
                                    suffix={input.options ? input.options.suffix : null}
                                    labelOnTop={input.labelLocation === 'above'}
                                    onFocus={(): void => this.onFieldFocus(input.field, input.fieldId)}
                                    onBlur={(): void => { }}
                                    color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                    readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                    highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                    protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                    reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                    underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                    hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                    alwaysVisibleLabel={this.alwaysVisibleLabel(input.field)}
                                  />
                                  : <TextDisplay input={input} formData={this.formData} dropdownValue={this.getDropdownValues(input.options, input.field)}></TextDisplay>
                                )}
                                {['string', 'label', 'multiline', 'number', 'datetime', 'date', 'time', 'dropdown'].includes(input.type) === false && (
                                  this.renderCustomComponent(input)
                                )}
                              </div>
                            )}
                            {input.rightLabel && (
                              <div className={this.getRightLabelClass(input)}>
                                <FLabel
                                  id={input.field}
                                  name={input.field}
                                  size={input.size}
                                  text={input.rightLabel}
                                  color={this.getCondition(this.conditions['inputs'], input.field, 'color')}
                                  readonly={this.getCondition(this.conditions['inputs'], input.field, 'readonly')}
                                  highintensity={this.getCondition(this.conditions['inputs'], input.field, 'highintensity')}
                                  protect={this.getCondition(this.conditions['inputs'], input.field, 'protect')}
                                  reverseimage={this.getCondition(this.conditions['inputs'], input.field, 'reverseimage')}
                                  underline={this.getCondition(this.conditions['inputs'], input.field, 'underline')}
                                  hidden={this.getCondition(this.conditions['inputs'], input.field, 'hidden')}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    ))
                    }
                  </form>
                </div>
              )}               
              {/* <!-- GRID --> */}
              {(this.screenDef.gridDefinition && this.formData.subfile) && (
                <div>
                  <div className="row">
                    <div id="toolbar" className={this.getToolbarClasses()}>
                      <div className="function-keys">
                        {this.functionKeys && (
                          <MegaMenu ref={(el: any): any => this.menu = el} model={this.getBottomButtons()} />
                        )}
                      </div>

                      {this.screenDef.showReport && (
                        <div className="top-buttons">
                          <div className="right-buttons">
                            <Tooltip target=".custom-tooltip-functionkey" position="top" />
                            <button className="export-button custom-tooltip-functionkey" data-pr-tooltip={`Generates Report having Products for the current GPI`} onClick={(): void => this.onGridSearchSubmit()}>Report</button>
                          </div>
                        </div>
                      )}
                      
                      <div className="export">
                        <div className="right-buttons">        
                          <button className="export-button" onClick={(): void => this.onGridSearchSubmit()}>Export as CSV</button>      
                          <div className="right-buttons" style={{display: this.screenDef.lowerContinueButton === true ? '' : 'none'}}>
                            <FContinueButton
                              id="continue-button"
                              title="Save"
                              handleContinue={(): void => this.onSubmit()}
                              disabled={!this.props.isActiveScreen}
                            />
                          </div >      
                        </div>
                      </div>                     
                    </div>
                  </div>

                  <div className="container-fluid" ref={(el: any): any => this.keysNavigation = el}>
                    {this.showGrid() && (
                      <OptumGrid
                        ref={(el: any): any => this.dataGrid = el}
                        model={this.formData}
                        screenDef={this.screenDef}
                        filterErrors={this.inputErrors}
                        onModelChange={(newModel: any): any => { this.formData = newModel; this.forceUpdate(); }}
                        onPageChange={(event: any): void => this.pageChanged(event)}
                        conditions={this.conditions}
                        onActionButton={this.tableAction}
                        actionsAsMenu={window.config['ui']['actionButtonMenu']}
                        actionMenuIcon={window.config['ui']['actionMenuIcon']}
                        actionMenuPos={window.config['ui']['actionMenuPos']}
                        dateFormat={this.dateFormat}
                        timeSeparator={this.timeSeparator}
                        showSeconds={this.showSeconds}
                        onTableFieldFocus={(fieldName: string, shortFieldName: string, rowIndex: number): void => this.onTableFieldFocus(fieldName, shortFieldName, rowIndex)}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          )
        }
      </React.Fragment >
    );
  }
}

export default BaseComponent;
